import React, { useState, useEffect, useCallback } from "react";
import "./Research.css";
const MAX_POSTS = 1;

// Debounce function (can be defined outside of the component)
function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const Research = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [allPostsLoaded, setAllPostsLoaded] = useState(false); // Add this line

  // Load more posts logic
  const loadMorePosts = useCallback(async () => {
    setLoading(true);

    // Simulate dynamic content loading
    // Inside loadMorePosts function
    setTimeout(() => {
      if (page >= MAX_POSTS) {
        setAllPostsLoaded(true); // No more posts to load
      } else {
        const newPosts = [`Post ${page}`, `Post ${page + 1}`];
        setPosts((prevPosts) => [...prevPosts, ...newPosts]);
        setPage((page) => page + 2);
      }
      setLoading(false);
    }, 1000);
  }, [page]);

  // Infinite scrolling logic
  useEffect(() => {
    const handleScroll = () => {
      // If loading or all posts are loaded, don't do anything
      if (loading || allPostsLoaded) return;

      // Check if the user is near the bottom of the page, add threshold for mobile browsers
      const threshold = 100; // Pixels from the bottom to start loading more posts
      if (
        window.innerHeight + document.documentElement.scrollTop + threshold >=
        document.documentElement.offsetHeight
      ) {
        loadMorePosts();
      }
    };

    // Debounce the scroll event
    const debouncedHandleScroll = debounce(handleScroll, 100);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [loading, loadMorePosts, allPostsLoaded]); // Add allPostsLoaded to dependencies array

  return (
    <div className="research">
      <h1>Research Breakthroughs in AI, Deep Learning and Machine Learning</h1>
      <p>
        The world of artificial intelligence (AI), deep learning, and machine
        learning is in a constant state of evolution, with breakthrough research
        emerging seemingly every day. These advancements are pushing the
        boundaries of what machines can do, with applications impacting
        everything from healthcare and climate change to our daily lives. Let's
        take a look at some of the most impactful breakthroughs in recent years:
      </p>
      <h3>The Rise of Large Language Models (LLMs)</h3>
      <p>
        LLMs like GPT-3, Jurassic-1 Jumbo, and Megatron-Turing NLG have
        revolutionized natural language processing (NLP). These models can
        generate human-quality text, translate languages, write different kinds
        of creative content, and answer your questions in an informative way.
        They've shown incredible capabilities in understanding and responding to
        natural language, paving the way for more advanced chatbots, writing
        assistants, and even personalized education tools.
      </p>
      <h3>Advances in Computer Vision</h3>
      <p>
        Deep learning architectures have significantly improved computer vision
        capabilities. Models can now recognize objects and scenes with
        near-human accuracy, enabling applications like self-driving cars,
        facial recognition, and medical image analysis. Recent breakthroughs
        include advances in object detection, image segmentation, and action
        recognition, leading to computers seeing the world with ever-greater
        precision and understanding.
      </p>
      <h3>Reinforcement Learning Takes the Stage</h3>
      <p>
        Reinforcement learning (RL) trains AI agents through trial and error,
        allowing them to learn complex tasks in dynamic environments. Recent
        successes include DeepMind's AlphaFold 2 protein structure prediction
        system, which has revolutionized biology research, and OpenAI's Dota 2
        bot, which can defeat professional players. RL is finding applications
        in robotics, game development, and even financial trading, with its
        potential to optimize decision-making in complex system
      </p>
      <h3>Federated Learning for Privacy-Preserving AI</h3>
      <p>
        Training AI models often requires vast amounts of data, raising privacy
        concerns. Federated learning addresses this by training models on
        decentralized devices without sharing individual data. This breakthrough
        enables collaborative AI development without compromising privacy,
        opening doors for personalized healthcare, secure smart cities, and
        distributed AI applications.
      </p>
      <h3>Towards Explainable AI</h3>
      <p>
        As AI models become more complex, understanding their decision-making
        process becomes crucial. Explainable AI (XAI) research aims to make AI
        models transparent and interpretable, building trust and ensuring
        responsible development. Recent advancements include visualizing model
        attention mechanisms and developing interpretable neural network
        architectures, allowing us to understand how and why AI models make
        certain decisions.
      </p>
      <p>
        These are just a few examples of the exciting breakthroughs happening in
        AI, deep learning, and machine learning. These advancements hold immense
        potential to improve our lives, but it's crucial to address ethical
        considerations and ensure responsible development as we move forward. As
        we continue to push the boundaries of what machines can do, it's
        important to remember that AI is a tool, and its ultimate impact depends
        on how we choose to use it.
      </p>
      <p>
        The future of AI is bright, and the next few years promise even more
        groundbreaking research and applications. This is just the beginning of
        a new era where AI will play an increasingly important role in shaping
        our world.
      </p>
      {posts.map((post, index) => (
        <p key={index}>{post}</p>
      ))}
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default Research;
