import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home/Home';
import Blog from './components/Blog/Blog';
import BlogPosts from './components/Blog/BlogPost';
import About from './components/About/About'; // Assuming you have other pages
import Research from './components/Research/Research'; // Assuming you have other pages
import './App.css';
import ContactUs from './components/ContactUs/ContactUs';

const App = () => {
    return (
        <Router>
            <div>
                <nav>
                    <ul className="navbar">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><Link to="/about">About </Link></li>
                        <li><Link to="/Research">Research </Link></li>
                        <li><Link to="/ContactUs">Contact Us </Link></li>
                    </ul>
                </nav>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/blog" element={<BlogPosts />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/research" element={<Research />} />
                    <Route path="/contactus" element={<ContactUs />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
