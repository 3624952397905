import React, { useState, useEffect, useCallback } from 'react';
import './Home.css';
const MAX_POSTS = 1;

// Static content defined outside the component
const homeContent = (
    
    <div className="home">
        <header className="header">
        <div className="logo"></div>
        <h1>Empowering Your Future with AI and Cloud Technology</h1>
        <p>Galilee Research</p>
        </header>
        <div className="content">
            <section id="vision">
                <h2> Artificial Intelligence (AI) and Cloud Technology</h2>
                <p>In the rapidly evolving landscape of technology, two areas have consistently stood at the forefront of innovation: Artificial Intelligence (AI) and Cloud Technology. As we delve into the depths of the digital era, it's crucial to understand how these technologies are shaping our world and what the future holds for them.</p>
                <h2>Artificial Intelligence: The Brain Behind Modern Tech</h2>
                <p>AI has transcended science fiction to become a fundamental part of our daily lives. From voice assistants to predictive analytics, AI's capabilities are vast and continually growing.</p>
            </section>
            <section id="why-choose-us">
                <h3>Machine Learning and Deep Learning</h3>
                <ul>
                    <li><strong>Machine Learning (ML),</strong> Pioneering in AI and cloud technologies to offer solutions that keep you ahead of the curve.</li>
                    <li><strong>Deep Learning,</strong> another AI subset, takes inspiration from the human brain, utilizing neural networks to process large sets of data. This approach has been pivotal in advancements such as facial recognition software and self-driving cars.</li>
                </ul>
            </section>
            <section id="services">
                <h2>Cloud Technology: The Backbone of Digital Infrastructure</h2>
                <p>Cloud technology has revolutionized data storage and computing, offering scalable, efficient, and cost-effective solutions. It provides the foundation for remote work, data analytics, and the Internet of Things (IoT).</p>
                <p>Businesses leveraging cloud computing can access vast computing resources on-demand, ensuring flexibility and resilience. This is crucial in a world where data is king, and processing needs can fluctuate dramatically.</p>
                
                
            </section>
            <section id="innovation">
                <h2>Synergy of AI and Cloud Technology</h2>
                <p>The convergence of AI and Cloud Technology is where the magic happens. Cloud-based AI offers unparalleled opportunities for innovation and efficiency. Companies can harness powerful AI algorithms without the need for extensive hardware, thanks to the cloud. This synergy enables real-time data processing and enhanced decision-making capabilities, driving growth in industries from healthcare to finance.</p>
            </section>
            <section id="Looking Ahead">
                <p>As we look to the future, the integration of AI and Cloud Technology will only deepen. We can expect advancements in areas like:</p>
                <ul>
                    <li><strong>Quantum Computing:</strong>Merging AI with emerging quantum computing technologies promises to unlock new levels of computational power.</li>
                    <li><strong>Edge Computing:</strong> Coupling AI with edge computing will facilitate faster, more efficient processing, particularly in IoT devices.</li>
                    <li><strong>Ethical AI:</strong>A growing focus on ethical AI will ensure that advancements in this field are aligned with human values and societal needs.</li>
                </ul>
            </section>

       
      </div>
      <footer className="footer">
      <p>Galilee Research - Your Gateway to AI and Cloud Technology Insights</p>
      </footer>
        </div>
    
);

// Debounce function (can be defined outside of the component)
function debounce(func, wait, immediate) {
    let timeout;
    return function() {
      const context = this, args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

const Home = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [allPostsLoaded, setAllPostsLoaded] = useState(false); // Add this line

    // Load more posts logic
    const loadMorePosts = useCallback(async () => {
        setLoading(true);

        // Simulate dynamic content loading
        // Inside loadMorePosts function
    setTimeout(() => {
    if (page >= MAX_POSTS) {
        setAllPostsLoaded(true); // No more posts to load
    } else {
        const newPosts = [`Post ${page}`, `Post ${page + 1}`];
        setPosts(prevPosts => [...prevPosts, ...newPosts]);
        setPage(page => page + 2);
    }
    setLoading(false);
}, 1000);
    }, [page]);

    // Infinite scrolling logic
    useEffect(() => {
        const handleScroll = () => {
          // If loading or all posts are loaded, don't do anything
          if (loading || allPostsLoaded) return;
      
          // Check if the user is near the bottom of the page, add threshold for mobile browsers
          const threshold = 100; // Pixels from the bottom to start loading more posts
          if (window.innerHeight + document.documentElement.scrollTop + threshold >= document.documentElement.offsetHeight) {
            loadMorePosts();
          }
        };
      
        // Debounce the scroll event
        const debouncedHandleScroll = debounce(handleScroll, 100);
      
        window.addEventListener('scroll', debouncedHandleScroll);
        return () => window.removeEventListener('scroll', debouncedHandleScroll);
      }, [loading, loadMorePosts, allPostsLoaded]); // Add allPostsLoaded to dependencies array

    return (
        <div className="home">
            {posts.length === 0 && homeContent}
            {posts.map((post, index) => (
                <p key={index}>{post}</p>
            ))}
            {loading && <p>Loading...</p>}
        </div>
    );
};

export default Home;
