import React from 'react';
import './Blog.css'; // Update this CSS file for better styling

const Blog = ({ title, content, author, date, tags = [], imageUrl, imageAlt }) => {
    return (
        <div className="blog">
           
            <h2>{title}</h2>
            <span>By {author} | {new Date(date).toLocaleDateString()}</span>
            {imageUrl && <img src={imageUrl} alt={imageAlt || 'Blog image'} />}
            <div className="blog-metadata">
                
                <div className="blog-tags">
                    {tags.map(tag => <span key={tag}>{tag}</span>)}
                </div>
            </div>
            <p>{content}</p>
            {/* Add interactive elements here */}
        </div>
    );
};


export default Blog;
